<template>
	<div>
	
		<b-card header-tag="header" v-b-toggle.collapse-1  class="my-2 mx-2">
			<template #header>
				<h6 class="mb-0">{{ "Časy k uložení do Redmine (" + to_commit.length + ")"  }}</h6>
			</template>
			
			<b-collapse id="collapse-1" class="mt-2">

		
				<b-table class="row-pointer" striped hover :items="to_commit" :fields="commit_fields">				
					<template #cell(buttons)="row">
					<b-button size="sm" class="mr-2" variant="danger" @click="deleteRow(row)">
						<span class="btn-inner--icon"><i class="fa fa-trash"></i></span>
					</b-button>
					<b-button size="sm" class="mr-2" variant="success" @click="continueTask(row)">
						<span class="btn-inner--icon"><i class="fa fa-retweet"></i> Pokračovat</span>
					</b-button>
				</template>
				</b-table>
				<b-button size="sm" v-if="to_commit.length > 0" class="mr-2" variant="success" @click="sendToRedmine()">
					<span class="btn-inner--icon"><i class="fa fa-letter"></i> Odeslat do Redmine</span>
				</b-button>
			</b-collapse>
		</b-card>
		<b-card class="my-2 mx-2" no-body>
			<b-card-body class="py-2 px-2">
				<b-form @submit="save" @reset="reset">
					<table class="table table-striped">
						<tbody>
							<tr>
								<th>Úkol</th>
								<td><v-select
									:options="issues"
									label="label"
									v-model="issue"
									ref="issue">
										<template #search="{attributes, events}">
											<input
											class="vs__search"
											:required="!issue"
											v-bind="attributes"
											v-on="events"
											/>
										</template>
									</v-select>
								</td>
							</tr>
							<tr>
								<th>Čas</th>
								<td><b-input-group>
										<template #prepend>
											<b-button size="sm" variant="success" @click="playPause()">
												<span class="btn-inner--icon"><i :class='"fa fa-" + (timer != null ? "pause" : "play")' /></span>
											</b-button>
										</template>
										<b-form-input v-model="time"/>
										
									</b-input-group>
								</td>
							</tr>
							<tr>
								<th>Popis</th>
								<td><b-form-textarea
									v-model="description"
									rows="3"
									max-rows="6"
									ref="description"
									required
									></b-form-textarea></td>
							</tr>
							<tr>
								<th></th>
								<td><b-button size="sm" type="reset" class="mr-2" variant="danger">
									<span class="btn-inner--icon"><i class="fa fa-trash" /> Reset</span>
								</b-button>
								<b-button type="submit" size="sm" class="mr-2" variant="success">
									<span class="btn-inner--icon"><i class="fa fa-save"></i> Uložit</span>
								</b-button></td>
							</tr>
						
						</tbody>
					</table>
				</b-form>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>

import axios from 'axios'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
	name: 'HelloWorld',
	components: {
		vSelect
	},
	data() {
		return {
			issues: [],
			isRunning: false,
			issue: null,
			time: "00:00",
			commit_fields: [{
					key: "label",
					label: "Projekt / úkol",
				},
				{
					key: "time",
					label: "Strávená doba",
				},
				{
					key: "description",
					label: "Poznámka",
				},
				{key: "buttons", label: "" }
			],
			to_commit: [],
			description: "",
			timer: null
		}
	},
	methods: {
		getProjects() {
			
// 			TESTING!
// 			this.issues = [{
// 				label: "guvno",
// 				issue_id: 666
// 				
// 			}]
			
			axios.get("https://redmine.msvelektronika.cz/issues.json?assigned_to_id=me").then(res => {
				console.log("DATA", res.data, );
				this.issues = res.data.issues.map(x => {
					return {...x, label: x.project.name + " - " + x.subject}
				});
			});
		},
		reset() {
			this.issue = null;
			this.description = "";
			this.time = "00:00";
			this.stopTimer();
			
		},
		sendToRedmine() {
	
			this.to_commit.forEach((value, index) => {
				
				let time = value.time.split(":");
				let hours = parseInt(time[0]);
				
				if (time.length > 1) {
					hours += parseInt(time[1]) / 60;
				}
				
				let entry = {
					time_entry: {
						issue_id: value.id,
						spent_on: moment().format('YYYY-MM-DD'),
						hours: hours,
						activity_id: 1, //prace
						comments: value.description,
					}
				};
				axios.post("https://redmine.msvelektronika.cz/time_entries.json", 
					entry
				).then(res => {
					if (res);
					this.to_commit = [];
					this.persist();
				});
			});
		},
		deleteRow(row) {
// 			debugger;
			this.to_commit.splice(row.index, 1);
		},
		save(event) {
			if (event) {
				event.preventDefault();
			}
			if (this.issue == null || this.description == "") return false;
			if (this.time.split(":").length != 2) return false;
			
			this.to_commit.push({
				...this.issue,
				issue: this.issue,
				time: this.time,
				description: this.description
			});
			
			this.reset();
		},
		playPause() {
			if (this.timer == null) {
				console.log("start");
				this.startTimer();
			} else {
				this.stopTimer();
			}
		},
		stopTimer() {
			clearInterval(this.timer);
			this.timer = null;
			this.isRunning = false;
		},
		startTimer() {
			this.isRunning = true;
			this.timer = setInterval(() => {
				console.log("tick");
				let parsed = this.time.split(':');
				this.time = moment().startOf("day").hours(parsed[0]).minutes(parsed[1]).add(1, "minutes").format("HH:mm");
			}, 60000);
		},
		continueTask(row) {
			//continue
			if (!this.save() && this.time != "00:00" && this.issue != null) return;
			
			this.issue = row.item.issue;
			this.time = row.item.time;
			this.description = row.item.description;
			
			this.deleteRow(row);
			
		},
		persist() {
			localStorage.to_commit = JSON.stringify(this.to_commit);
			localStorage.issue = JSON.stringify(this.issue);
			localStorage.description = this.description;
			localStorage.time = this.time;
			localStorage.isRunning = this.isRunning;
			console.log("saved to storage");
		}
	},
	created() {
		console.log("created");
		
		this.getProjects();
	},
	mounted() {
		console.log("mounted");
		
		try {
			if (localStorage.to_commit) {
				this.to_commit = JSON.parse(localStorage.to_commit);
			}
			if (localStorage.issue) {
				this.issue = JSON.parse(localStorage.issue);
			}
			if (localStorage.description) {
				this.description = localStorage.description;
			}
			if (localStorage.time) {
				this.time = localStorage.time;
			}
			if (localStorage.isRunning && localStorage.isRunning == "true") {
				this.startTimer();
			}
		} catch(err) {
			//zatim nic
			console.log("Local storage load error", err);
		}
		
		setInterval(() => {
			this.persist();
		}, 2000);

	}
}
</script>


<style scoped>

</style>
